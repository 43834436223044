import _ from 'lodash'
import { BootstrapData } from '../types'
import { Model } from './model'
import { AppSpecData } from '@wix/thunderbolt-symbols'
import { MasterPageId, EVENT_TYPES_MAP } from './constants'
import { IControllerEvents } from './ControllerEvents'

export interface WixCodeViewerAppUtils {
	createWixCodeAppData(
		appData: AppSpecData
	): {
		userCodeMap: Array<{
			url: string
			displayName: string
			id: string
			scriptName: string
		}>
	}
	setStaticEventHandlers(eventHandlers: EventHandlers): void
}

type EventHandlers = {
	[fnName: string]: () => void
}

export default function({
	bootstrapData,
	models,
	createSdkHandlers,
	controllerEventsFactory
}: {
	bootstrapData: BootstrapData
	models: Model
	createSdkHandlers: (pageId: string) => any
	controllerEventsFactory: IControllerEvents
}) {
	const { wixCodePageIds } = bootstrapData.wixCodeBootstrapData
	const handlers = createSdkHandlers(bootstrapData.currentPageId)
	const { orderedControllers, staticEvents } = models.platformModel
	const [componentStaticEvents, controllerStaticEvents] = _.partition(staticEvents, (event) => !_.includes(orderedControllers, event.compId))

	function staticHandlersForComponents(fnName: string, handler: () => void) {
		const event = _.find(componentStaticEvents, { callbackId: fnName })
		if (event) {
			const { compId, eventType } = event
			handlers.registerEvent(compId, EVENT_TYPES_MAP[eventType], handler)
		}
	}

	function staticHandlersForControllers(fnName: string, handler: () => void) {
		const event = _.find(controllerStaticEvents, { callbackId: fnName })
		if (event) {
			const { compId, eventType } = event
			controllerEventsFactory.createScopedControllerEvents(compId).on(eventType, handler)
		}
	}

	return {
		createWixCodeAppData() {
			return {
				userCodeMap: [MasterPageId, bootstrapData.currentPageId!]
					.filter((pageId) => wixCodePageIds[pageId])
					.map((pageId: string) => ({
						url: wixCodePageIds[pageId],
						displayName: pageId === MasterPageId ? 'site' : `${bootstrapData.platformEnvData.site.pageIdToTitle[pageId]} page`,
						id: pageId,
						scriptName: `${pageId}.js`
					}))
			}
		},
		setStaticEventHandlers: (eventHandlers: EventHandlers) => {
			const [componentsHandlers, controllersHandlers] = _.partition(Object.keys(eventHandlers), (fnName) => _.find(componentStaticEvents, { callbackId: fnName }))
			Object.entries(componentsHandlers).forEach(([, fnName]) => {
				staticHandlersForComponents(fnName, eventHandlers[fnName])
			})
			Object.entries(controllersHandlers).forEach(([, fnName]) => {
				staticHandlersForControllers(fnName, eventHandlers[fnName])
			})
		}
	}
}
